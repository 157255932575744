module.exports = [{
      plugin: require('/Users/m/Work/manuis.in/node_modules/gatsby-plugin-layout/gatsby-browser'),
      options: {"plugins":[],"component":"/Users/m/Work/manuis.in/src/components/Layout.js"},
    },{
      plugin: require('/Users/m/Work/manuis.in/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-130227707-1"},
    },{
      plugin: require('/Users/m/Work/manuis.in/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
